"use client";

import { useState, forwardRef, useMemo } from "react";
import EmailIon from "./SvgIcons/emailIcon";
import PasswordIcon from "./SvgIcons/passwordIcon";
import PersonIcon from "./SvgIcons/personIcon";
import SearchIcon from "./SvgIcons/searchIcon";
import ErrorIcon from "./SvgIcons/errorIcon";
import EyeIcon from "./SvgIcons/eyeIcon";
import Button from "../Button/button";
import PromoIcon from "./SvgIcons/promoIcon";
import OpenEyeIcon from "./SvgIcons/openEyeIcon";
import PhoneIcon from "./SvgIcons/phone";
import CarIcon from "./SvgIcons/carIcon";
import OrderIcon from "./SvgIcons/orderIcon";
import CompanyIcon from "./SvgIcons/company";
import MapIcon from "./SvgIcons/mapIcon";
import CrossIcon from "./SvgIcons/crossIcon";

const Input = (props, ref) => {
  const {
    id,
    label,
    error,
    type,
    icon,
    message,
    required,
    ClassName = "",
    clearIcon = false,
    // hideTypeIcon = true,
    onSearch = () => {},
    onClear = () => {},
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const determineSVG = useMemo(() => {
    switch (icon) {
      case "text":
        return <PersonIcon />;
      case "email":
        return <EmailIon />;
      case "password":
        return <PasswordIcon />;
      case "search":
        return <SearchIcon onClick={onSearch} />;
      case "promo":
        return <PromoIcon />;
      case "phone":
        return <PhoneIcon />;
      case "car":
        return <CarIcon />;
      case "order":
        return <OrderIcon />;
      case "company":
        return <CompanyIcon />;
      case "map":
        return <MapIcon />;
      case "cross":
        return <CrossIcon onClick={onClear} />;
      default:
        return;
    }
  }, [icon, onSearch, onClear]);

  return (
    <div className="input-container relative">
      <div className="flex">
        {label ? (
          <div className="flex">
            <label className="label" htmlFor={id}>
              {label}
            </label>
            {required ? <span className="text-error">*</span> : ""}
          </div>
        ) : null}
      </div>
      <div className="w-12/12 block relative">
        {message && (
          <textarea
            className={`input ${ClassName} ${error ? "error" : ""}`}
            id={id}
            ref={ref}
            {...rest}
          />
        )}
        {!message && (
          <>
            {!!determineSVG && (
              <div className="input-img-box">{determineSVG}</div>
            )}
            {clearIcon && (
              <div className="input-img-box close-search-icon">
                <CrossIcon onClick={onClear} />
              </div>
            )}

            <input
              type={showPassword ? "text" : type}
              className={`input ${ClassName} ${error ? "error" : ""}`}
              id={id}
              ref={ref}
              {...rest}
            />
            {type === "password" && (
              <Button
                theme="icononly"
                type="button"
                onClick={handleShowPassword}
                className="absolute top-6/12 -translate-y-1/2 right-4"
              >
                {showPassword ? <OpenEyeIcon /> : <EyeIcon />}
              </Button>
            )}
          </>
        )}
      </div>
      {error && (
        <div className="error-message">
          <ErrorIcon className="me-[5px] min-w-[13px]" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Input);
