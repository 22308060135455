import Button from "../../Button/button";
import Cross from "@/assets/icon/gsf-close.svg";

const CrossIcon = (props) => {
  return (
    <Button className="p-0" {...props}>
      <Cross />
    </Button>
  )
};
export default CrossIcon;