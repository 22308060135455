const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3077 15.5C1.80257 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.1974 0.5 13.6923V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80257 19.5 2.3077V13.6923C19.5 14.1974 19.325 14.625 18.975 14.975C18.625 15.325 18.1974 15.5 17.6923 15.5H2.3077ZM18 3.44225L10.4865 8.25188C10.4096 8.29546 10.3301 8.32975 10.2481 8.35475C10.166 8.37975 10.0833 8.39225 9.99998 8.39225C9.91664 8.39225 9.83395 8.37975 9.7519 8.35475C9.66985 8.32975 9.59037 8.29546 9.51345 8.25188L1.99998 3.44225V13.6923C1.99998 13.782 2.02883 13.8557 2.08653 13.9134C2.14423 13.9711 2.21795 14 2.3077 14H17.6923C17.782 14 17.8557 13.9711 17.9134 13.9134C17.9711 13.8557 18 13.782 18 13.6923V3.44225ZM9.99998 6.99998L17.8461 1.99998H2.15383L9.99998 6.99998ZM1.99998 3.673V2.52978V2.55957V2.52785V3.673Z"
        fill="#8C9194"
      />
    </svg>
  );
};
export default EmailIcon;
