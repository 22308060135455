const OpenEyeIcon = () => {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1544_17523" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1544_17523)">
<path d="M12.0023 15.5769C13.1354 15.5769 14.0978 15.1803 14.8894 14.3871C15.6811 13.5939 16.0769 12.6308 16.0769 11.4977C16.0769 10.3646 15.6803 9.40224 14.8872 8.61058C14.094 7.81891 13.1308 7.42308 11.9977 7.42308C10.8647 7.42308 9.90229 7.81966 9.11062 8.61282C8.31895 9.40601 7.92312 10.3692 7.92312 11.5023C7.92312 12.6353 8.3197 13.5977 9.11287 14.3894C9.90605 15.181 10.8692 15.5769 12.0023 15.5769ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.56252 12.8875 9.30002 12.25 9.30002 11.5C9.30002 10.75 9.56252 10.1125 10.0875 9.58748C10.6125 9.06248 11.25 8.79998 12 8.79998C12.75 8.79998 13.3875 9.06248 13.9125 9.58748C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 18.5C9.8949 18.5 7.96959 17.9384 6.22409 16.8153C4.47858 15.6923 3.08531 14.2147 2.04429 12.3827C1.96096 12.2391 1.90006 12.0944 1.86159 11.9485C1.82313 11.8027 1.80389 11.653 1.80389 11.4995C1.80389 11.346 1.82313 11.1965 1.86159 11.051C1.90006 10.9054 1.96096 10.7609 2.04429 10.6173C3.08531 8.78525 4.47858 7.30769 6.22409 6.18463C7.96959 5.06154 9.8949 4.5 12 4.5C14.1051 4.5 16.0304 5.06154 17.7759 6.18463C19.5215 7.30769 20.9147 8.78525 21.9557 10.6173C22.0391 10.7609 22.1 10.9056 22.1384 11.0514C22.1769 11.1973 22.1961 11.347 22.1961 11.5005C22.1961 11.654 22.1769 11.8035 22.1384 11.949C22.1 12.0945 22.0391 12.2391 21.9557 12.3827C20.9147 14.2147 19.5215 15.6923 17.7759 16.8153C16.0304 17.9384 14.1051 18.5 12 18.5ZM12 17C13.8834 17 15.6125 16.5041 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81664 18.7625 8.47914 17.1875 7.48748C15.6125 6.49581 13.8834 5.99998 12 5.99998C10.1167 5.99998 8.38752 6.49581 6.81252 7.48748C5.23752 8.47914 4.03335 9.81664 3.20002 11.5C4.03335 13.1833 5.23752 14.5208 6.81252 15.5125C8.38752 16.5041 10.1167 17 12 17Z" fill="#8C9194"/>
</g>
</svg>
    );
  };
  export default OpenEyeIcon;
  